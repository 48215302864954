import MasterService from './MasterService';
import moment from 'moment';
const _ = require("lodash");


const DealerService = {
    ...MasterService,
    getSkusDetails(dealer_id_hash) {
        return this.post('/dealer/subscription/list', { dealer_id_hash: dealer_id_hash })
    },
    saveSubscriptions(row, dealer_id_hash) {
        let validity_to_date = new Date();
        let b_details_sku_comment = "";
        if (row && row.master && row.master.id == 36) {
            validity_to_date = validity_to_date.setDate(validity_to_date.getDate() + row.master.sku_validity);
            validity_to_date = moment(validity_to_date).format('YYYY-MM-DD');
            b_details_sku_comment = "Auction Buyer";
        }

        if (row.b_details_id == null) {
            row = {
                "b_details_sku_id": row.master.id,
                "b_details_validity_from": new Date(),
                "b_details_validity_to": validity_to_date,
                "b_details_org_validity_to": new Date(),
                "b_details_selling_price": row.master.sku_mrp,
                "b_details_is_active": row.b_details_is_active,
                "b_details_sku_comment": b_details_sku_comment
            }
        }
        let data = {
            dealer_id_hash: dealer_id_hash,
            skus: [row]
        }        
        if (row.b_details_id != null) {
            return this.post('/dealer/subscription/edit', data)
        }
        else {
            return this.post('/dealer/subscription/add', data)
        }
    },
    addBookingDetails(rows, dealer_id_hash) {
        if (!rows || !rows[0]) return Promise.resolve({ status: 200 })
        // // return this.getSkusDetails(dealer_id_hash);
        // if (row.b_details_id == null) {
        //     row = {
        //         "b_details_sku_id": row.master.id,
        //         "b_details_validity_from": new Date(),
        //         "b_details_validity_to": new Date(),
        //         "b_details_org_validity_to": new Date(),
        //         "b_details_selling_price": row.master.sku_mrp,
        //         "b_details_is_active": row.b_details_is_active,
        //         "b_details_sku_comment": ""
        //     }
        // }
        let data = {
            dealer_id_hash: dealer_id_hash,
            skus: rows
        }
        if (rows[0].b_details_id != null) {
            return this.post('/dealer/subscription/edit', data)
        }
        else {
            return this.post('/dealer/subscription/add', data)
        }
    },
    getSettingContacts(dealer_id_hash) {
        return this.get('/dealer/dealer/setting/contact', { dealer_id_hash: dealer_id_hash })
    },
    contactSettingSave(dealer_id_hash, _list) {
        return this.post('/dealer/dealer/setting/contact/save', { dealer_id_hash: dealer_id_hash, contact_settings: _list })
    },
    getEmployees(dealer_id_hash) {
        return this.post('/dealer/user-manager/list', { dealer_id_hash: dealer_id_hash })
    },
    saveKycDetails(dealerKycSaveJson) {
        return this.post('/dealer/dealer/kyc/save', dealerKycSaveJson)
    },
    saveKycDocDetails(dealerKycDocSaveJson) {
        return this.post('/dealer/dealer/kyc/doc/save', dealerKycDocSaveJson)
    },
    saveDiffDocDetails(dealerKycDocSaveJson) {
        return this.post('/dealer/inventory-funding/save_kyc_doc_info', dealerKycDocSaveJson)
    },
    setBankAsPrimary(primarydata) {
        return this.post('/dealer/dealer/kyc/set_bank_acc_primary', primarydata)
    },
    saveKycBankDocDetails(dealerKycDocSaveJson) {
        return this.post('/dealer/dealer/kyc/doc/save_kyc_doc', dealerKycDocSaveJson)
    },
    verifyDealerSubscriptionForAffiliateLink(postData) {
        return this.post('/dealer/dealer/is_dealer_subscription_for_affiliate_link', postData)
    },
    saveKycCallVerificationDetails(dealerKycCallVerificationSaveJson) {
        return this.post('/dealer/dealer/kyc/call_verification/save', dealerKycCallVerificationSaveJson)
    },
    getKycDetails(dealer_id_hash) {
        return this.get('/dealer/dealer/kyc', { dealer_id_hash: dealer_id_hash })
    },
    getAuctionDepositeDetails(dealer_id_hash) {
        return this.get('/dealer/dealer/auction/depositeDetails', { dealer_id_hash: dealer_id_hash })
    },
    saveAuctionDepositeDetails(auctionDepositeSaveJson) {
        return this.post('/dealer/dealer/auction/depositeAmount/save', auctionDepositeSaveJson)
    },
    getAllAuctionDeposites(postData) {
        return this.post('/dealer/dealer/auction/getAuctionDepositeList', postData)
    },
    getAllDepositeReceiptList() {
        return this.get('/dealer/dealer/auction/deposite/allReceiptList') 
    },
    saveDepositeReceipt(postData) {
        return this.post('/dealer/dealer/auction/deposite/receipt/save', postData);  
    },
    downloadDepositeReceipt(postdata) {
        return this.post('/dealer/dealer/auction/download/receipt', postdata);  
    },
    getDocKycDetails(dealer_id_hash) {
        return this.get('/dealer/dealer/kyc/doc', { dealer_id_hash: dealer_id_hash })
    },
    kycDealerCallVerificationData(dealer_id_hash) {
        return this.get('/dealer/dealer/kyc/call_verification_list', { dealer_id_hash: dealer_id_hash })
    },
    getdashboardcard() {
        return this.get('/dealer/dealer/dashboard',{})
    },
    getdashboardleadcard(dashboardCountJson){
        return this.post('/lead/lead/get-dashboard-counts',dashboardCountJson) 
    },
    getBasicDetails(postdata){
        return this.post('/dealer/dealer/details',postdata) 
    },
    getDealerFormInfo(postdata){
        return this.post('/dealer/dealer/getDealerFormInfo',postdata) 
    },
    getOwnerDetails(postdata){
        return this.post('/dealer/user-manager/owner-detail',postdata) 
    },
    getDealertypeList(){
        return this.get('/dealer/dealer/master/dealer_type',{})
    },
    getAccountManagerList(){
        return this.get('/dealer/account-manager/ac_manager_list',{})
    },
    getKycMasterDetails(){
        return this.get('/dealer/dealer/master/kyc_master',{})
    },
    getOutletList(postdata){
        return this.post('/dealer/showroom/list',postdata) 
    },
    syncBookingDetails(postdata){
        return this.put('/dealer-cron/cron/update-subscription',postdata) 
    },
    saveBasicinfo(postdata,method){
        return this[method]('/dealer/dealer/save_basic_info',postdata)
    },
    getSubscriptionHistoryLog(dealer_id_hash) {
        return this.post('/dealer/subscription/get_subscription_history_log', { dealer_id_hash: dealer_id_hash })
    },
    saveAccountManagerData(postdata,method){
        return this[method]('/dealer/account-manager/add_ac_manager',postdata)
    },
    getAccountManagerData(postdata){
        return this.post('/dealer/account-manager/get_ac_manager',postdata)
    },
    saveoutletInfo(postdata, save_outlet_url) {
        return this.post('/' + save_outlet_url, postdata)
    },
    loginCaptcha() {
        return this.get("/account/user/get_captcha_image?t="+Date.now());
    },
    async validateDealer(dealer_id_hash, dealer_id) {
        let response_status = {basic_details:false,dealership_details:false}
        let postdata = { dealer_id_hash }
        let response =  await this.getBasicDetails(postdata);
            if (response.data.status == 200 && response.status == 200) {
                let responsedata = response.data.data;
                if( !_.has(responsedata[0], 'organization') || responsedata[0].organization=="") response_status.basic_details=true;
                if( !_.has(responsedata[0], 'dealership_email') || responsedata[0].dealership_email=="") response_status.basic_details=true;
                if( !_.has(responsedata[0], 'dealership_contact') || responsedata[0].dealership_contact=="") response_status.basic_details=true;
                if( !_.has(responsedata[0], 'stock_category') || !responsedata[0].stock_category.length) response_status.basic_details=true;
                if( !_.has(responsedata[0], 'dealer_type_ids') || !responsedata[0].dealer_type_ids.length) response_status.basic_details=true;
            } 
        
        
        response = await this.getOwnerDetails({dealer_id})
        if (response.data.status == 200 && response.status == 200) {
                let responsedata = response.data.data;
                if( !_.has(responsedata[0], 'name') || responsedata[0].name=="") response_status.basic_details=true;
                if( !_.has(responsedata[0], 'email') || responsedata[0].email=="") response_status.basic_details=true;
                if( !_.has(responsedata[0], 'mobile') || responsedata[0].mobile=="") response_status.basic_details=true;
        };

        const dealership_details_params = {
            "dealer_id":dealer_id,
            "_with":[
                "id","dealer_id","name","address","description","type",
                "contact_number","is_primary","city_id","state_id","locality_id",
                "pincode","latitude","longitude","is_lat_long_verified","website_url",
                "opening_timings","lead_preference_cities","can_lead_cities", "additional_address"
                ]
            }
        response = await this.getOutletList(dealership_details_params);
        if (response.data.status == 200 && response.status == 200) {
            let responsedata = response.data.data;
            
            if(!_.has(responsedata[0], 'name') || responsedata[0].name=="") response_status.dealership_details=true;
            if(!_.has(responsedata[0], 'address') || responsedata[0].address.split('|')[0]=="") response_status.dealership_details=true;
            if(!_.has(responsedata[0], 'contact_number') || responsedata[0].contact_number=="") response_status.dealership_details=true;
            if(!_.has(responsedata[0], 'type') || responsedata[0].type=="") response_status.dealership_details=true;
            if(!_.has(responsedata[0], 'is_primary') || responsedata[0].is_primary=="") response_status.dealership_details=true;
            if(!_.has(responsedata[0], 'city_id') || responsedata[0].city_id=="") response_status.dealership_details=true;
            if(!_.has(responsedata[0], 'state_id') || responsedata[0].state_id=="") response_status.dealership_details=true;
            if(!_.has(responsedata[0], 'locality_id') || responsedata[0].locality_id=="") response_status.dealership_details=true;
        };
        
        return response_status;

    },
    async dealerStatusChange(requestParams){
        const response = await MasterService.post('dealer/dealer/status_change', requestParams);
        return response;
    },

    updateDealerSizeFourFieldToZero(postdata){
        return this.post('/dealer/showroom/edit_dealer_size_four_field',postdata) 
    }

}

export default DealerService;
